export default {
  buildMode: "prod",
  diaryApiBaseUrl: "https://diaryapi-slot.learnbody.co.kr/",
  diaryReportApiBaseUrl: "https://dairyreportapi.learnbody.co.kr/",
  guideApiBaseUrl: "https://learnbodyguideapi-slot.azurewebsites.net/",
  marketApiBaseUrl: "https://learnbodymarketapi-slot.azurewebsites.net/",
  marketStorageUrl: "https://learnbodykr.blob.core.windows.net/diary-images/",
  cdnUrl: "https://learnbodykr.blob.core.windows.net/cdn/",
  storageDIR: "diary-user-photo",
  shopByBaseUrl: "https://shop-api.e-ncp.com/",
  shopByClientId: "xRzkr7sOuawIgcDtOgSWSA==",
  communityDomain: "https://communityapp-slot.learnbody.co.kr/",
  marketDomain: "https://learnbodymarket-slot.azurewebsites.net",
  guideDomain: "https://learnbodyguide-slot.azurewebsites.net",
  paycrewDomain: "https://learnbodyschedule-slot.azurewebsites.net/",
  adropUrl: 'https://api-rest.adrop.io/',
  adropId: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6IjAxSjVXRkdSVEFRS0E1UTBOWFNGTjlIQUdCOjAxSkE5NVZTMFNHVlJKVk5CRVFCVDJZUllFIiwia2V5VHlwZSI6MSwiaWF0IjoxNzI5MDMyNDc5LCJleHAiOjI1MzQwMjEyODAwMH0.IDL3WpdK8Z0wki8YZIVQ0lBt1jl3Ej-sJ2tG7SN7TYA'
};
